import branding from "../image/refer-frnd.png";
import { useState, useRef, useEffect } from "react";
import { useAlert } from "react-alert";
import { Result_Overlay } from "./commonFunction";
import MasonryDesignComponent from "../masonryDesignComponent";
import Masonry from "react-masonry-component";
import height from "./commonFunction/height";

import temp3sparkalleft from "../image/template25-top-left.png";
import temp3sparkalright from "../image/template25-cake.png";
import temp5bottomright from "../image/temp5-bottom-right.png";
import template25gift from "../image/template25-gift.png";
import temp5star from "../image/temp5-star.png";
import temp5lineclip from "../image/temp5-lineclip.png";
import { useLocation } from "react-router-dom";







const masonryOptions = {
	transitionDuration: 0,
};
const imagesLoadedOptions = { background: ".my-bg-image-el" };

const backgroundFrame = "/frame/background/background-frame7.png";


export const NewDesign5 = ({
	data,
	templateData,
	title,
	index,
	handleClick,
	clickedTabs,
	sendData,
	showTabs,
	message,
	shareImage,
	color,
	defaultImage,
	overlay,
	setDownloadCard,
	inlineHandleClick,
	pouupContent,
	handleMessageChange,
	bannerThemeResponse,
	setIsDownload,
}) => {
	const messageStyle = {
		whiteSpace: "pre-wrap",
		color: `${data?.message?.color}`,
		fontSize: `${data?.message?.size}px`,
		fontWeight: `${data?.message?.fontWeight}`,
		lineHeight: "1.5",
		textAlign: `${data?.message?.textAlign}`,
		fontFamily: `${data?.message?.fontFamily}`,
		fontStyle: `${data?.message?.fontStyle}`,
		textDecoration: `${data?.message?.textDecoration}`
	};

	const DynamicOpacity = {
		opacity: data?.message?.opacity ? data?.message?.opacity : "0.2",
	}

	let showMessage = "";
	if (!shareImage) {
		showMessage = JSON.parse(templateData);
	} else {
		showMessage = templateData;
	}
	const captureDivRef1 = useRef(null);
	const alert = useAlert();
	const [downloadText, setDownloadText] = useState({
		text: "Download",
		disabled: false,
	});
	const [showInstagramPost, setShowInstagramPost] = useState(false);

	const [imageLoaded, setImageLoaded] = useState(true);

	const handleImageLoad = () => {
		setImageLoaded(true);
	};


	const [setDynamicHeight, setSetDynamicHeight] = useState(0);

	useEffect(() => {
		const resultItemsDiv = document.querySelector('.result_items');
		if (resultItemsDiv) {
			const height = resultItemsDiv.offsetHeight;
			localStorage.setItem('dynamicHeight', height);
			setSetDynamicHeight(height);
		}
	}, []);


	const [uploadedImage, setUploadedImage] = useState(defaultImage);

	const location = useLocation();

	const handleImageUpload = (e) => {
		const { name } = e.target;
		if (name === "image") {
			const file = e.target.files[0];
			if (file) {
				const imageURL = URL.createObjectURL(file); // Generate a URL for the uploaded file
				console.log(imageURL, "image preview URL");
				setUploadedImage(imageURL); // Set the image URL to the state
				localStorage.setItem('uploadedImg', imageURL);
			}
		}
	};

	const [isEditable, setIsEditable] = useState(true);
	const [editableMessage, setEditableMessage] = useState(message);
	// Handle changes after user finishes editing (onBlur event)
	const handleBlur = (event) => {
		setIsEditable(true);

		const newMessage = `${editableMessage}`;
		// console.log( '>>>', editableMessage);
		setEditableMessage(newMessage);
		handleMessageChange(newMessage);
		setIsEditable(true);
	};

	const [isActiveEdit, setIsActiveEdit] = useState(true);

	const activeClassHandle = () => {
		setIsActiveEdit(true);
	};
	const deactivateEdit = () => {
		setIsActiveEdit(false);
	};
	// // Update the local state when showMessage.message prop changes
	useEffect(() => {
		setEditableMessage(showMessage.message);
	}, [showMessage.message]);


	const design = (
		<>
			<div className="result_items new-ai-template5">
				{/* <button onClick={()=>{inlineHandleClick("message")}}>Click Me</button> */}

				{!shareImage && (
					<Result_Overlay
						clickedTabs={clickedTabs}
						index={index}
						downloadText={downloadText}
						setDownloadText={setDownloadText}
						captureDivRef1={captureDivRef1}
						title={title}
						sendData={sendData}
						showMessage={showMessage}
						showTabs={showTabs}
						handleClick={handleClick}
						data={data}
						alert={alert}
						design={"FiftheenDesign2"}
						setDownloadCard={setDownloadCard}
						isDisabled={!imageLoaded}
						setShowInstagramPost={setShowInstagramPost}
						bannerThemeResponse={bannerThemeResponse}
						setIsDownload={setIsDownload}
					/>
				)}
				{
					<div
						ref={captureDivRef1}
						className="inner_result_data design_one"
						style={{
							backgroundColor: `${data?.div?.background}`,
							backgroundSize:
								clickedTabs?.tabs === "NewTemplates"
									? ""
									: `${data.image.backgroundCover && "cover"}`,
						}}
					>

						<img className=" temp3sparkal-left" src={temp3sparkalleft} alt="temp3tree" />
						<img className=" temp3sparkal-right" src={temp3sparkalright} alt="temp3tree" />

						<div
							className={`editor-page edit-page-bg ${pouupContent === "background_image" && "active"
								}`}
							onClick={() => {
								inlineHandleClick("background_image");
							}}
						>
							<img src={"image/edit-page/eidt-page-bg.png"} />
						</div>
						<div
							className="edit-background-frame"
							style={{
								opacity: `${DynamicOpacity.opacity}`,
								backgroundImage: `url(${data.image?.backgroundFrame?.includes("storage")
									? `${process.env.REACT_APP_URL_LOCAL}/${data.image?.backgroundFrame}`
									: data.image?.backgroundFrame || backgroundFrame
									})`,
							}}
						></div>


						<div className="result-template-img">
							<div
								className={`editor-page edit-page-image ${pouupContent === "image_edit" && "active"
									}`}
								onClick={() => {
									inlineHandleClick("image_edit");
								}}
							>
								<img src={"image/edit-page/eidt-page-image.png"} />
							</div>
							<img className="template5-gift" src={template25gift} alt="temp3tree" />
							<img className="temp5-star" src={temp5star} alt="temp3tree" />
							<img className="temp5-lineclip" src={temp5lineclip} alt="temp3tree" />
							<div className="new-ai-template5-img new-ai-templates-img">

								<div
									className={`result-template-inner-img ${showInstagramPost && "insta-post"
										}`}
								>
									<div className="greeting-book-inner-img">
										<span className="add-photo-icon">
											<i className="fa-light fa-image"></i> Add Photo
										</span>


										{uploadedImage && (
											<img
												className="forth-design-new-image"
												id="image-div"
												src={uploadedImage}
												alt="Uploaded Preview"
											/>
										)}

										{window.location.pathname === "/greeting-card-design-studio" && data.image.link && (
											<img
												src={data.image.link}
												id="image-div"
												className=""
												onLoad={handleImageLoad}
												alt="Greeting Card Image"
											/>
										)}


										{location.pathname !== "/greeting-card-design-studio" && (
											<input
												id="choose-temp-img"
												className="cstm-form"
												type="file"
												accept="image/*"
												name="image"
												onChange={handleImageUpload}
											/>
										)}
									</div>
									{overlay && (
										<div
											className="template-overlay"
											style={{
												background: "#000",
												opacity: `${data?.image?.opacity}`,
											}}
										></div>
									)}
									{!imageLoaded && (
										<div
											style={{ width: "100%", height: "100%" }}
											className="please-wait new-please-wait"
										>
											<div class="skype-loader">
												<div class="dot">
													<div class="first"></div>
												</div>
												<div class="dot"></div>
												<div class="dot"></div>
												<div class="dot"></div>
											</div>
										</div>
									)}
									{/* <img
									className="forth-design-new-image"
									id="image-div"
									src={defaultImage}
									onLoad={handleImageLoad}
								/> */}
								</div>
							</div>
						</div>
						<div className="temp5-result-inner">
							<div
								className="result-inner"
								style={{
									maxWidth: data?.div?.background ? "90%" : "",
									borderRadius:
										clickedTabs?.tabs === "NewTemplates"
											? ""
											: `${data?.div?.borderRadius}`,
									padding:
										clickedTabs?.tabs === "NewTemplates"
											? ""
											: `${data?.div?.padding}`,
									fontFamily:
										clickedTabs?.tabs === "NewTemplates"
											? ""
											: `${data?.div?.fontFamily}`,
									color:
										clickedTabs?.tabs === "NewTemplates"
											? ""
											: `${data?.div?.color}`,
									opacity:
										clickedTabs?.tabs === "NewTemplates"
											? ""
											: `${data?.div?.is_opaque === "yes"
												? data?.div?.opacity
												: "inherit"
											}`,
									borderColor:
										clickedTabs?.tabs === "NewTemplates"
											? ""
											: `${data?.div?.borderColor}`,
								}}
							>
								<p
									style={messageStyle}
									// className="template-message"
									className={`template-message ${isActiveEdit ? 'editable' : ''}`}
									onClick={activeClassHandle}
									onMouseLeave={deactivateEdit}
									id="template-message"
									onBlur={handleBlur}
									contentEditable={isEditable}
								>
									{editableMessage}
								</p>
								<div
									className={`editor-page edit-page-text ${pouupContent === "message_edit" && ""
										}`}
									onClick={() => {
										inlineHandleClick("message_edit");
									}}
								>
									<img src={"image/edit-page/eidt-page-text.png"} />
								</div>
							</div>
						</div>

						<img className="temp5bottom-right" src={temp5bottomright} alt="temp3tree" />

						<p className="branding-logo ">
							<img src={branding} alt="branding" />
							Created with Varnz.ai
						</p>
						{!imageLoaded && (
							<div
								style={{ width: "100%", height: "100%" }}
								className="please-wait new-please-wait"
							>
								<div class="skype-loader">
									<div class="dot">
										<div class="first"></div>
									</div>
									<div class="dot"></div>
									<div class="dot"></div>
									<div class="dot"></div>
								</div>
							</div>
						)}
					</div>
				}
			</div>
		</>
	);
	return design;
};
